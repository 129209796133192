.fade_rule {
    height: 1px;
    background-color: #e6e6e6;
    width: 66em;
    margin: 0 auto;
    background-image: linear-gradient(to left, white 2%, #e6e6e6 50%, white 98%);
    background-image: -o-linear-gradient(to left, white 2%, #e6e6e6 50%, white 98%);
    background-image: -moz-linear-gradient(to left, white 2%, #e6e6e6 50%, white 98%);
    background-image: -webkit-linear-gradient(to left, white 2%, #e6e6e6 50%, white 98%);
    background-image: -ms-linear-gradient(to left, white 2%, #e6e6e6 50%, white 98%);
    background-image: -webkit-gradient(
        linear,
        left bottom,
        right bottom,
        color-stop(0.02, white),
        color-stop(0.5, gray),
        color-stop(0.98, white)
    );
}

.salesforce-setting-menu-left {
    padding: 20px;

    .salesforce-setting-link {
        padding: 10px;

        &:focus {
            color: #344767 !important;
        }

        &:active {
            color: #344767 !important;
        }

        &:hover {
            text-decoration: none !important;
            background-color: #e9ecef !important;
            color: #344767 !important;
            border-radius: 10px;
        }

        .material-icons {
            padding: 0 10px;
        }

        .MuiTypography-displayBlock {
            display: flex !important;
        }
    }

    .MuiTypography-colorPrimary {
        color: #344767 !important;
    }
}

.btn-sf-stg {
    background-color: #000 !important;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    border-radius: 10px !important;

    .MuiButton-label {
        font-weight: 700 !important;
    }
}

.text-bold {
    font-weight: 600;
}

.avatar {
    width: 100px;
    position: relative;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
    border-radius: 50%;
    vertical-align: middle;
}

#root {
    background-color: #f8f9fa;
}

.text-sm {
    font-size: 16px;
    line-height: 1.5;
}

.text-right {
    text-align: right;
}

.fileinput {
    width: 100%;
}

.taishou-desc {
    a {
        &:visited {
            color: #e91e63 !important;
        }
        &:link {
            color: #e91e63 !important;
        }
    }
}

.table-responsive {
    .MuiTableCell-head {
        color: #7b809a !important;
    }
}

.text-light-bold {
    font-weight: 400 !important;
}
