.min-height-customer-details {
  min-height: 385px;
}
.min-height-customer-details-explain {
  min-height: 550px;
}
.table-edit-customer {
  .MuiLoadingButton-root {
    background-color: #e91e63;
  }
}
.min-height-customer-history {
  min-height: 500px;
}

.call-phone {
  .MuiLoadingButton-root {
    background-color: #e91e63;
  }
}
.stickyFixed {
  .MuiTableCell-stickyHeader {
    top: 0;
    left: 0;
    z-index: 2;
    position: inherit;
    background-color: #fafafa;
  }
}
#everphone {
  z-index: 1401;
}
.semantic-ui-wrapper .sc-launcher {
  background-color: #4e8cff;
  background-position: 50%;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 25px;
  box-shadow: none;
  z-index: 1400;
}

.semantic-ui-wrapper .sc-chat-window {
  z-index: 1401 !important;
  width: 370px;
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.overflow-y {
  overflow-y: scroll;
  width: 50%;
  height: calc(100vh);
}

.height-customer-details {
  min-height: 550px;
}
.card-body {
  padding: 0 !important;
  margin-top: 5px;
}
.button-change {
  margin-left: -13px !important;
}
.textinput {
  float: left;
  width: 100%;
  min-height: 129px;
  outline: none;
  resize: none;
  border: 1px solid grey;
}
