// variables and mixins
@import "salto-react/variables";
@import "salto-react/mixins";
@import "salto-react/shadows";

// plugin css
@import "salto-react/plugins/plugin-nouislider";
@import "salto-react/plugins/plugin-perfect-scrollbar";
@import "salto-react/plugins/plugin-react-datetime";
@import "salto-react/plugins/plugin-react-bootstrap-sweetalert";
@import "salto-react/plugins/plugin-react-chartist";
@import "salto-react/plugins/plugin-react-big-calendar";
@import "salto-react/plugins/plugin-react-jvectormap";
@import "salto-react/plugins/plugin-react-table";
@import "salto-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "salto-react/misc";
@import "salto-react/fileupload";
@import "salto-react/fixed-plugin";

// Component SCSS
@import "bootstrap/dist/css/bootstrap.min.css";
@import "salto-react/common";
@import "salto-react/component/salesforce-setting";
@import "salto-react/component/history-mapping";
@import "salto-react/component/data-table";
@import "salto-react/component/schedule-setting";
@import "salto-react/component/call-history-list";
@import "salto-react/component/evercall-setting";
@import "salto-react/component/customer-list";
@import "salto-react/component/dashboard";
@import "salto-react/component/history-detail";
@import "salto-react/component/data-customer-details";
@import "salto-react/component/kintone-setting";
@import "salto-react/component/status-manager";
