.schedule-setting-title-right {
    color: #000;
    text-align: right;
    font-size: 1rem;
}

.schedule-setting-title {
    color: #000;
    font-size: 1rem;
    margin-bottom: 0;
}

.schedule-setting-subtitle {
    color: #7b809a;
    font-size: 0.875rem;
}

.btn-setting {
    .MuiButton-root {
        padding: 0;
    }

    .MuiMenuItem-root {
        padding-left: 10px !important;
        &:hover {
            background: #e9ecef;
            color: #344767;
            box-shadow: none;
            border-radius: 5px;
        }
    }
}

.spin-loading {
    width: 20px !important;
    height: 20px !important;
    vertical-align: middle;

    .MuiCircularProgress-svg {
        width: 100%;
        height: 100%;
        top: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }
}

.dropdown-addition {
    margin: auto !important;

    .MuiButton-root {
        width: auto !important;
        background: linear-gradient(195deg, #747b8a 0%, #495361 100%) !important;
        border-radius: 0.5rem !important;
    }

    .MuiMenuItem-root {
        padding-left: 10px !important;
        &:hover {
            background: #e9ecef;
            color: #344767;
            box-shadow: none;
            border-radius: 5px;
        }
    }
}

.setting-card-icon {
    background: linear-gradient(195deg, #42424a 0%, #191919 100%) !important;
    border-radius: 0.75rem !important;
    box-shadow: none !important;
}

.batch-card-icon {
    background: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
    border-radius: 0.75rem !important;
    box-shadow: none !important;
}

.danger-card-icon {
    background: linear-gradient(195deg, #ef5350 0%, #e53935 100%) !important;
    border-radius: 0.75rem !important;
    box-shadow: none !important;
}

.btn-schedule-setting {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
}

.text-danger {
    color: #f44335 !important;
}

.w-50 {
    width: 50% !important;
}

.w-100 {
    width: 100% !important;
}

.btn-save-schedule-setting {
    background: linear-gradient(195deg, #42424a 0%, #191919 100%) !important;
    padding: 0.375rem 1rem !important;
    border-radius: 0.5rem !important;
}

.text-info {
    color: #1a73e8 !important;
}

.text-warning {
    color: #fb8c00 !important;
}

.text-light {
    color: #e9ecef !important;
}

.spinner-success {
    height: 15px;
    width: 15px;
    background: #4caf50;
    border-radius: 10px;
}

hr.horizontal.dark {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.btn-search-table {
    padding: 6px 16px !important;

    .MuiSvgIcon-root {
        color: #fff !important;
    }
}

.checkbox-circle-20 {
    .MuiSvgIcon-root {
        border-radius: 5px;
    }

    .checked-icon {
        width: 20px !important;
        height: 20px !important;
        border-radius: 5px !important;
        background-color: #f50057 !important;
        color: #fff !important;
    }

    .indeterminate-icon {
        width: 20px !important;
        height: 20px !important;
        border-radius: 5px !important;
        background-color: rgba(0, 0, 0, 0.54) !important;
        color: #fff !important;
    }

    .Mui-disabled {
        .MuiSvgIcon-root {
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}

.batch-list-input {
    .MuiFormLabel-root {
        padding-top: 16px !important;
    }

    .MuiFormControl-root {
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        margin-top: 16px !important;
    }
}

.batch-filter-value {
    .MuiFormControl-root {
        margin: 0 0 26px 0 !important;
    }
}

.capture-error-paginate {
    .MuiTablePagination-input + .MuiTablePagination-caption {
        display: none;
    }
}

.batch-log-card {
    margin-bottom: 10px !important;
}

.batch-card-icon.batch-kintone {
    background: linear-gradient(195deg, #e1bc3b 0%, #cda404 100%) !important;
}

.checkbox-circle-result {
    .checked-icon {
        width: 25px !important;
        height: 25px !important;
    }
}

.cardHeaderResult {
    svg {
        margin: 0 !important;
    }
}

.uncheckedIconResult {
    width: 25px !important;
    height: 25px !important;
}

.resultButton {
    text-align: right;
}

.MuiTooltip-tooltip {
    padding: 10px !important;
    background-color: #000000 !important;
    font-size: 14px !important;
    max-width: 200px !important;
    text-align: center !important;

    .MuiTooltip-arrow {
        color: #000000 !important;
    }
}

.error-search-bar {
    .MuiInputBase-input {
        font-size: 15px !important;
    }
}
