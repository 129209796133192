.btn-salesforce {
  background: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
  padding: 8px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
  margin-left: 5px !important;

  .icon-loading {
    width: 18px !important;
    height: 18px !important;
    margin-left: 5px !important;
  }
}

.btn-search-extend {
  padding: 8px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
}

.btn-kintone {
  padding: 8px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
}

.btn-export {
  margin-left: 5px !important;
  padding: 5px 10px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;

  .icon-loading {
    width: 15px !important;
    height: 15px !important;
    margin-left: 5px !important;
  }
}
.callHistoryTable span.MuiButtonBase-root.MuiTableSortLabel-root {
  font-size: 10px;
  font-weight: bold;
}

.callHistoryTable td.MuiTableCell-root.MuiTableCell-body {
  font-size: 10px;
}

.labelIconName {
  align-items: baseline;
}

.callResultLabel .everphone {
  color: #339537;
  padding: 3px 5px 3px 5px;
  border-radius: 5px;
  font-size: 8px;
}
.everphone .customer-list-badge-info {
  width: auto !important;
}
.everphone .badgesCustom .MuiBadge-badge {
  color: white !important;
  background-color: #74cb78 !important;
}
.evercall .badgesCustom {
  width: auto !important;
}
.audioBox {
  position: relative;
  width: max-content;
}
.audioBox::-webkit-media-controls-play-button,
.audioBox::-webkit-media-controls-panel {
  background-color: transparent;
  color: transparent;
}

.playButton {
  position: absolute;
  width: 13px;
  left: 21px;
  top: 17px;
}

.otherButton {
  position: absolute;
  width: 20px;
  left: 20px;
  top: 16px;
}

.imagePlayButton {
  width: 100%;
}
