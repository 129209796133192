.customer-info-card {
    margin-top: 0px !important;

    .customer-info {
        text-align: center;
        position: relative;
        color: $grey-color !important;

        .name {
            color: $navy-night-color !important;
        }

        .avatar {
            border-radius: 50% !important;
            box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
            width: 74px !important;
            height: 74px !important;
        }

        .MuiBadge-badge {
            margin: auto !important;
            padding: 10px !important;
            border-radius: 8px !important;

            .badge-icon {
                font-size: 16px !important;
                vertical-align: middle !important;
            }
        }

        .customer-info-icon {
            font-size: 20px !important;
            vertical-align: middle !important;
        }

        .btn-collapse {
            text-align: left !important;
            color: $navy-night-color !important;
            line-height: 1.5 !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            width: 100% !important;
            border-bottom: 1px solid $light-grey-color !important;
            padding: 16px 16px !important;

            .MuiButton-label {
                width: 100% !important;
                align-items: center !important;
                justify-content: space-between !important;

                &:focus {
                    background-color: $white-color !important;
                    color: $navy-night-color !important;
                }
            }
        }

        .title-head {
            font-weight: 700 !important;
            color: $navy-night-color !important;
            text-align: left !important;
            padding: 5px 16px 0px 16px !important;
            font-size: 14px !important;
        }

        .collapse-biko {
            text-align: left !important;
            padding: 10px 16px !important;

            .title {
                font-weight: 700 !important;
                color: $navy-night-color !important;
            }
        }

        .collapse-detail {
            text-align: left !important;
            padding: 10px 48px !important;

            .title {
                font-weight: 700 !important;
                color: $navy-night-color !important;
            }
        }
    }
}

.tel-history-card {
    margin-top: 0 !important;
    color: #7b809a !important;

    .tel-history-info {
        padding: 16px !important;
        font-size: 12px !important;

        .tel-history-time-detail {
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        .result {
            font-size: 16px !important;
        }

        .tel-history-icon {
            width: 20px !important;
            height: 20px !important;
        }

        .call-icon {
            font-weight: 700 !important;
            color: #339537 !important;
            font-size: 20px !important;
            vertical-align: middle !important;
        }

        .summary-detail {
            padding-top: 16px !important;
            margin: 0 !important;
            width: 100% !important;
            border-bottom: 1px solid $light-grey-color !important;
        }

        .detail {
            margin: 0 !important;
            padding: 8px !important;
            width: 100% !important;
            border-bottom: 1px solid $light-grey-color !important;
        }

        .title {
            font-weight: 400;
        }

        .MuiBadge-badge {
            padding: 10px !important;
            border-radius: 8px !important;

            .badge-icon {
                font-size: 16px !important;
                vertical-align: middle !important;
            }
        }

        .tel-history-audio {
            max-width: 400px !important;
        }
    }
}

.tel-history-time {
    margin-top: 0 !important;
}
