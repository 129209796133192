.data-table {
    box-shadow: none !important;

    .MuiTableCell-stickyHeader {
        background-color: #fff;
        color: #7b809a;
    }

    .MuiTableCell-head {
        color: #7b809a;
        font-size: 18px !important;
    }

    .MuiTableCell-body {
        color: #7b809a;
        font-size: 15px !important;
    }

    .hide-pagination {
        .MuiTablePagination-actions,
        .MuiTablePagination-input + .MuiTablePagination-caption {
            display: none;
        }
    }

    .pagination-rose {
        .Mui-selected {
            color: #fff !important;
            background-color: #e91e63 !important;
        }
    }

    .MuiInputBase-input {
        font-size: 15px !important;
    }
}
.width-customer-history {
    height: 700px;
}
.table-edit-customer {
    display: contents;
}
.invalid-feedback {
    display: contents;
    width: 100%;
    margin-top: 0.25em;
    font-size: 0.875em;
    color: #dc3545;
}
