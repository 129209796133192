.avatar-evercall {
    width: 100px;
    position: relative;
    vertical-align: middle;
}

.text-title-color {
    color: #344767 !important;
}

.evercall-card-space {
    margin-bottom: 0 !important;
}

.evercall-input {
    .MuiFormControl-root {
        padding-top: 10px !important;
    }
}

.btn-evercall-setting {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    border-radius: 10px !important;

    .MuiButton-label {
        font-weight: 700 !important;
    }
}
