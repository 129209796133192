.btn-add-status {
    border-radius: 10px !important;
}

.btn-action-status-manager {
    .btn-phone {
        background-color: #4caf50;
    }

    .btn-detail {
        background-color: #1a73e8;
        &:hover {
            background-color: #1a73e8;
        }
    }

    .MuiButtonBase-root {
        &:first-child {
            margin-right: 5px !important;
        }

        min-width: 30px !important;
        border-radius: 8px !important;
        width: 30px !important;
        height: 30px !important;

        .material-icons {
            line-height: 1.5 !important;
            font-size: 20px !important;
        }
    }
}

.btn-save-status {
    .icon-loading {
        width: 18px !important;
        height: 18px !important;
        margin-left: 5px !important;
    }
}
