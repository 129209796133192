.btn-list-creation {
  padding: 8px 16px !important;
  background: linear-gradient(195deg, #747b8a 0%, #495361 100%) !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
}

.btn-search-extend {
  padding: 8px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
}

.btn-kintone {
  padding: 8px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;

  .icon-loading {
    width: 18px !important;
    height: 18px !important;
    margin-left: 5px !important;
  }
}

.btn-salesforce {
  background: linear-gradient(195deg, #49a3f1 0%, #1a73e8 100%) !important;
  padding: 8px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
  margin-left: 5px !important;

  .icon-loading {
    width: 18px !important;
    height: 18px !important;
    margin-left: 5px !important;
  }
}

.btn-export {
  margin-left: 5px !important;
  padding: 5px 10px !important;
  border-radius: 0.5rem !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;

  .icon-loading {
    width: 15px !important;
    height: 15px !important;
    margin-left: 5px !important;
  }
}

.btn-action-customer-list {
  .btn-phone {
    background-color: #4caf50;
  }

  .btn-detail {
    background-color: #1a73e8;
    &:hover {
      background-color: #1a73e8;
    }
  }

  .MuiButtonBase-root {
    &:first-child {
      margin-right: 5px !important;
    }

    min-width: 30px !important;
    border-radius: 8px !important;
    width: 30px !important;
    height: 30px !important;

    .material-icons {
      line-height: 1.5 !important;
      font-size: 20px !important;
    }
  }
}

.tags-search-extend {
  .MuiChip-root {
    background: #000;
    color: #fff;

    .MuiChip-deleteIcon {
      color: #fff;
    }
  }

  .MuiFormControl-root {
    min-width: 25%;
    width: auto;
  }

  .MuiChip-deleteIcon {
    width: 15px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    height: 15px;
    padding-left: 2px;
  }
}

.customer-list {
  margin-top: 0 !important;

  .MuiTableCell-root {
    font-size: 12px !important;
  }
}

.hide-pagination {
  .MuiTablePagination-actions,
  .MuiTablePagination-input + .MuiTablePagination-caption {
    display: none;
  }
}

.pagination-rose {
  .Mui-selected {
    color: #fff !important;
    background-color: #e91e63 !important;
  }
}

.list-creation-modal-footer {
  .btn-close-custom {
    background-image: linear-gradient(195deg, #ebeff4 0%, #ced4da 100%);
    color: #3a416f;
    border-radius: 0.5rem;
    width: 64px;
    height: 32px;

    &:hover {
      color: #3a416f;
    }
  }

  .btn-create-custom {
    background-image: linear-gradient(195deg, #ec407a 0%, #d81b60 100%);
    border-radius: 0.5rem;
    width: 64px;
    height: 32px;

    .icon-loading {
      width: 12px !important;
      height: 12px !important;
      margin-left: 5px !important;

      .MuiCircularProgress-svg {
        width: 12px !important;
        height: 12px !important;
        display: block !important;
      }
    }
  }
}

.customer-list-badge-success {
  width: 100% !important;
  .MuiBadge-badge {
    font-weight: 700 !important;
    font-size: 0.5rem !important;
    color: #339537 !important;
    background-color: #bce2be !important;
    position: relative !important;
    border-radius: 5px !important;
    transform: none !important;
  }
}

.customer-list-badge-info {
  width: 100% !important;
  .MuiBadge-badge {
    font-weight: 700 !important;
    font-size: 0.5rem !important;
    color: #095bc6 !important;
    background-color: #aecef7 !important;
    position: relative !important;
    border-radius: 5px !important;
    transform: none !important;
  }
}

.customer-list-badge-warning {
  width: 100% !important;
  .MuiBadge-badge {
    font-weight: 700 !important;
    font-size: 0.5rem !important;
    color: #c87000 !important;
    background-color: #ffd59f !important;
    position: relative !important;
    border-radius: 5px !important;
    transform: none !important;
  }
}

.customer-list-badge-secondary {
  width: 100% !important;
  .MuiBadge-badge {
    font-weight: 700 !important;
    font-size: 0.5rem !important;
    background-color: #d7d9e1 !important;
    color: #6c757d !important;
    position: relative !important;
    border-radius: 5px !important;
    transform: none !important;
  }
}

.customer-list-badge-danger {
  width: 100% !important;
  .MuiBadge-badge {
    font-weight: 700 !important;
    font-size: 0.5rem !important;
    color: #721c24;
    background-color: #f8d7da;
    position: relative !important;
    border-radius: 5px !important;
    transform: none !important;
  }
}

.customer-list-result-icon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px !important;
}

.text-gray {
  cursor: pointer;
  color: #7b809a !important;
  a {
    color: #7b809a;
    i {
      color: #fff;
    }
  }
}

.icon-loading-alert {
  margin: auto !important;
}

.list-creation-input {
  .MuiFormLabel-root {
    padding-top: 16px !important;
  }

  .MuiFormControl-root {
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 15px !important;
  }
}

.list-creation-filter-value {
  .MuiFormControl-root {
    margin: 0 0 28px 0 !important;
  }
}
.result-name-filter {
  .MuiAutocomplete-root {
    margin-bottom: 17px !important;
  }

  .MuiAutocomplete-popper {
    color: #7b809a !important;
  }
}

.customer-table {
  .MuiTableCell-root {
    padding: 4px 16px !important;
  }

  .MuiTableCell-head {
    font-size: 18px !important;
  }

  .MuiTableCell-body {
    font-size: 15px !important;
  }

  .MuiInputBase-input {
    font-size: 15px !important;
  }
}

.filter-phone {
  .MuiFormLabel-root {
    padding: 8px 0 !important;
  }

  .MuiFormControl-root {
    padding-top: 2px !important;
  }
}

.search-font-size {
  .form-control {
    font-size: 14px !important;
  }
}

.text-grey {
  color: #7b809a !important;
}

.text-dark-grey {
  color: #344767 !important;
}

.custom-autocomplete {
  .MuiInput-root {
    &:before {
      border-bottom: 1px solid #d2d2d2 !important;
    }

    color: #7b809a !important;
  }
}
