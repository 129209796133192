.color-red {
    color: #f44335 !important;
}

.color-blue {
    color: #1a73e8 !important;
}

.line-table {
    width: 100%;
    height: 15px;
    border-bottom: 1px solid #ddd;
}

.input-search {
    padding-top: 0 !important;
}

.backdrop {
    .MuiBackdrop-root {
        z-index: 1100;
    }

    .MuiCircularProgress-root {
        color: white;
    }
}

.input-search-select {
    padding: 15px !important;
}

.MuiTableRow-root:first-child {
    .MuiTableCell-root {
        border-top: none !important;
    }
}

.disable-overflow-table {
    overflow-x: initial !important;
}

.btn-history-setting {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
}

.btn-save-history-mapping {
    padding: 8px 16px !important;
    border-radius: 0.5rem !important;
    font-weight: 700 !important;
}