.btn-loading-pink .MuiLoadingButton-root {
  background-color: $pink;
  padding: 5px 16px !important;
  border-radius: 0.5rem !important;
  font-weight: 500 !important;
  color: #fff;
  &:hover {
    background-color: $pink;
  }
}

.custom-input {
  &.padding {
    padding: 8px;
  }
  label {
    font-size: 21px;
    color: #7b809a;
  }
}

.customer-tag {
  .MuiAutocomplete-tag {
    background-color: #d7d7d7;
    color: #5f5f5f;
  }
  .MuiAutocomplete-endAdornment {
    color: #9c27b0;
  }
}

.custom-input-tag {
  .MuiInput-underline {
    border-bottom: none;
    &:focus :after {
      border-bottom: 2px solid #9c27b0;
    }
  }
}
.search-extra .MuiInput-underline {
  .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }
  &:after {
    border-bottom: 2px solid #9c27b0 !important;
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #d2d2d2 !important;
  }
  &:before {
    border-bottom: 1px solid #d2d2d2 !important;
  }
}

.form-helper {
  .MuiInputBase-colorSuccess {
    &:after {
      border-bottom: 2px solid #4caf50;
    }
  }
  .MuiInput-root:after {
    border-bottom: 2px solid #4caf50;
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #4caf50;
  }
  .Mui-error:after {
    border-bottom: 2px solid #d32f2f !important;
  }
}

.error-detail {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;

  .detail-item {
    border-bottom: 1px solid #ccc;
    width: 100%;
  }
}